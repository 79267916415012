import { format, isYesterday } from "date-fns";

const formateDate = (date: any, formate?: string) => {
  const dateObj = new Date(date);
  let dateFormat = '';

  if (isYesterday(dateObj)) {
    dateFormat = formate ? 'MMM dd, '+ formate : "MM/dd/yyyy";
  } else {
    dateFormat = formate ? formate : "MM/dd/yyyy";
  }
  const formattedDate = format(dateObj, dateFormat);
  return formattedDate;
};
export { formateDate };
