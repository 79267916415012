import React from "react";
import { Button, UncontrolledPopover, PopoverBody } from "reactstrap";
interface EndButtonsProps {
  onSubmit: () => void;
  disabled: boolean;
}
const EndButtons = ({ onSubmit, disabled, recording, stopRecording, startRecording }: any) => {

  return (
    <div className="chat-input-links ms-2 gap-md-1">
      <div className="links-list-item d-none d-sm-block">
        {/* <div id="audio-btn"></div> */}
        {/* <div>
          <button className="btn btn-link text-decoration-none btn-lg waves-effect" onClick={recording ? stopRecording : startRecording} id="audio-btn">
            {recording ? 'Stop Recording' : 'Start Recording'}
          </button>


        </div> */}
        {/* <Button
          onClick={recording ? stopRecording : startRecording}
          color="none"
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          id="audio-btn"
        >
          <i className="bx bx-microphone align-middle"></i>
        </Button> */}
      </div>
      {/* <UncontrolledPopover trigger="focus" placement="top" target="audio-btn">
        <PopoverBody>
          {
            recording ?
              (<PopoverBody>
                <div className="loader-line">
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                </div>
              </PopoverBody>) : ('stopped')
          }
          {recording ? 'Stop Recording' : 'Start Recording'}
        </PopoverBody>
      </UncontrolledPopover> */}
      <div className="links-list-item">
        <Button
          color="primary"
          type="submit"
          // disabled={disabled}
          className="btn btn-primary btn-lg chat-send waves-effect waves-light"
        >
          <i className="bx bxs-send align-middle"></i>
        </Button>
      </div>
    </div>
  );
};

export default EndButtons;
