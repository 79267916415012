import { toast } from "react-toastify";

const showSuccessNotification = (message: any) => {
  if (typeof message === 'string') {
    toast.success(message);
  } else {
    toast.success(message.message);
  }

};

const showErrorNotification = (error: any) => {

  if (typeof error === 'string') {
    toast.error(error);
  } else {
    toast.error(error?.message);
  }
};

export { showSuccessNotification, showErrorNotification };
