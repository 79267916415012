import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getContacts = (filters?: object) => {
  return api.get(url.GET_CONTACTS, filters);
};

const inviteContact = (data: object) => {
  return api.create(url.CREATE_CONTACT, data);
};
const getSingleContact = (id: string | number) => {
  return api.get(url.GET_SINGLE_CONTACT + id);

};
const updateContact = (id: string | number, data: any) => {
  console.log(id, 'this id come from api')
  return api.update(url.UPDATE_CONTACT + id, data)
}
export { getContacts, inviteContact, getSingleContact, updateContact };
