import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";

interface DataTypes {
  to: string | null;

  businessNumber: string | null;
  contactId: string | null;
  email: string | null;
  description: string | null;
  address: string | null;
  contact_name: string | null;



}

interface UpdateContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (data: DataTypes) => void;
}
const tamplateList = [
  { label: "Hello World", value: "hello_world" },
  { label: "Hello World", value: "hello_world1" },
  { label: "Hello World", value: "hello_world2" },

  // Add more templates as needed
];
const UpdateContactModal = ({
  isOpen,
  onClose,
  onUpdateContact,
  updateModalData
}: any) => {
  const [selectedTemplate, setSelectedTemplate] = useState(tamplateList[0].value);

  console.log(updateModalData, 'default selected template')


  const [data, setData] = useState<DataTypes>({
    to: null,
    businessNumber: null,
    contactId: null,
    email: null,
    address: null,
    description: null,
    contact_name: null,
  });

  useEffect(() => {
    setData({
      to: updateModalData.whatsAppContact,
      businessNumber: "284177978114673",
      contactId: updateModalData.contactId,
      email: updateModalData.email,
      address: updateModalData.address,
      description: updateModalData.description,
      contact_name: updateModalData.name
    });
  }, [updateModalData?._id]);
  const onChangeData = (field: keyof DataTypes, value: string) => {
    setData({ ...data, [field]: value });
  };

  const onUpdateClick = () => {
    const newData = { ...data };

    onUpdateContact(newData, updateModalData._id);
  };
  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable>
      <ModalHeader toggle={onClose} className="bg-primary">
        <div className="modal-title-custom text-white font-size-16 ">
          Update Contact
        </div>
      </ModalHeader>
      <ModalBody className="p-4">
        <Form>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Contact Name
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter Contact Name"
              value={data["contact_name"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("contact_name", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Phone Number
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter phone number"
              value={data["to"] || ""}
              disabled={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("to", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Contact Id
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter contact id"
              value={data["contactId"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("contactId", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Address
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter Address"
              value={data["address"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("address", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Email
            </Label>
            <Input
              type="email"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter  Email"
              value={data["email"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("email", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Description
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter description"
              value={data["description"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("description", e.target.value);
              }}
            />
          </div>

        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={onClose}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={onUpdateClick}
          disabled={!data.to}
        >
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateContactModal;
