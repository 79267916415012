import React, { useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";


// components
import LightBox from "../../../components/LightBox";


//images
import imagePlaceholder from "../../../assets/images/users/user-dummy-img.jpg";

// interface
import {
  MessagesTypes,
  ImageTypes,
  AttachmentTypes,
} from "../../../data/messages";

// hooks
import { useProfile } from "../../../hooks";

// utils
import { formateDate } from "../../../utils";
import RepliedMessage from "./RepliedMessage";

interface MenuProps {
  onDelete: () => any;
  onReply: () => any;
  onForward: () => void;
}

const Menu = ({ onDelete, onReply, onForward }: MenuProps) => {
  return (
    <UncontrolledDropdown className="align-self-start message-box-drop">
      <DropdownToggle className="btn btn-toggle" role="button" tag={"a"}>
        <i className="ri-more-2-fill"></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
          onClick={onReply}
        >
          Reply <i className="bx bx-share ms-2 text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
          onClick={onForward}
        >
          Forward <i className="bx bx-share-alt ms-2 text-muted"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
        >
          Copy <i className="bx bx-copy text-muted ms-2"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
        >
          Bookmark <i className="bx bx-bookmarks text-muted ms-2"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between"
          to="#"
        >
          Mark as Unread <i className="bx bx-message-error text-muted ms-2"></i>
        </DropdownItem>
        <DropdownItem
          className="d-flex align-items-center justify-content-between delete-item"
          onClick={onDelete}
        >
          Delete <i className="bx bx-trash text-muted ms-2"></i>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
interface ImageMoreMenuProps {
  imagelink: any,
  onReply: () => any;
  onDelete: () => void;
}
const ImageMoreMenu = ({ imagelink,onReply, onDelete }: ImageMoreMenuProps) => {
  return (
    <div className="message-img-link">
      <ul className="list-inline mb-0">
        <UncontrolledDropdown
          tag="li"
          color="none"
          className="list-inline-item dropdown"
        >
          <DropdownToggle tag="a" role="button" className="btn btn-toggle">
            <i className="bx bx-dots-horizontal-rounded"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              className="dropdown-item d-flex align-items-center justify-content-between"
              href={imagelink}
              download
            >
              Download <i className="bx bx-download ms-2 text-muted"></i>
            </DropdownItem>
            <DropdownItem
              tag="a"
              className=" d-flex align-items-center justify-content-between"
              href="#"
              onClick={onReply}
            >
              Reply <i className="bx bx-share ms-2 text-muted"></i>
            </DropdownItem>
            <DropdownItem
              tag="a"
              className=" d-flex align-items-center justify-content-between"
              href="#"
              data-bs-toggle="modal"
              data-bs-target=".forwardModal"
            >
              Forward <i className="bx bx-share-alt ms-2 text-muted"></i>
            </DropdownItem>
            <DropdownItem
              tag="a"
              className=" d-flex align-items-center justify-content-between"
              href="#"
            >
              Bookmark <i className="bx bx-bookmarks text-muted ms-2"></i>
            </DropdownItem>
            <DropdownItem
              tag="a"
              className=" d-flex align-items-center justify-content-between delete-item"
              href="#"
              onClick={onDelete}
            >
              Delete <i className="bx bx-trash ms-2 text-muted"></i>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </ul>
    </div>
  );
};

interface ImageProps {
  message : MessagesTypes,
  image: ImageTypes;
  onImageClick: (id: number) => void;
  index: number;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  onDeleteImg: (imageId: string | number) => void;
}


const Images = ({ message, images, onSetReplyData, onDeleteImg }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const onImageClick = (id: number) => {
    setSelected(id);
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  console.log(images, 'this is imagesx')

  return (
    <>
      <div className="message-img mb-0">
        <React.Fragment>
      <div className="message-img-list">
        <div>
          <Link
            className="popup-img d-inline-block"
            to={"#"}
                onClick={() => onImageClick(message._id)}
          >
                <img src={`${process.env.REACT_APP_URL}/${images}`} alt="" className="rounded border" />
          </Link>
            </div>
      </div>
        </React.Fragment>

      </div>
      {isOpen && (
        <LightBox
          isOpen={isOpen}
          images={`${process.env.REACT_APP_URL}/${images}`}
          onClose={onClose}
          defaultIdx={selected}
        />
      )}
    </>
  );
};

interface AttachmentsProps {
  attachments: AttachmentTypes[] | undefined;
}


const Typing = () => {
  return (
    <p className="mb-0">
      typing
      <span className="animate-typing">
        <span className="dot mx-1"></span>
        <span className="dot me-1"></span>
        <span className="dot"></span>
      </span>
    </p>
  );
};
interface MessageProps {
  message: any;
  chatUserDetails: any;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  isFromMe: boolean;
  onOpenForward: (message: MessagesTypes) => void;
  isChannel: boolean;
  onDeleteImage: (messageId: string | number, imageId: string | number) => void;
  // onSetReplyImageData: () => void;
}
const Message = ({
  message,
  chatUserDetails,
  onDelete,
  onSetReplyData,
  isFromMe,
  onOpenForward,
  isChannel,
  onDeleteImage,
}: MessageProps) => {
  const { userProfile } = useProfile();

  let hasAuido;
  let hasImages;
  let hasPdf;
  let isOgg;
  let isMp3;
  let hasTemplate;
  if (message.messageType == 'template') {
    hasTemplate = true
  }

  if (message?.media[0] !== undefined) {
    const media = message?.media[0];
    if (media.mimeType === 'image/jpeg') {
      hasImages = true;
    }
    if (media.mimeType === 'application/pdf') {
      hasPdf = true;

    }
    if (media.mimeType === 'audio/mp3' || media.mimeType === 'audio/ogg; codecs=opus') {
      hasAuido = true;
      if (media.mimeType === 'audio/mp3') {
        isMp3 = true;
      }
      if (media.mimeType === 'audio/ogg; codecs=opus') {
        isOgg = true;
      }
    }
  }

  const hasAttachments = message.attachments && message.attachments.length;
  let hasText = false;
  if (message.messageType == 'text') {
    hasText = true;
  }

  const isTyping = false;

  const chatUserFullName = chatUserDetails.name
    ? `${chatUserDetails.name}`
    : "-";

  const myProfile = userProfile.profileImage
    ? userProfile.profileImage
    : imagePlaceholder;
  const channeluserProfile = imagePlaceholder;
  const chatUserprofile = imagePlaceholder;
  const profile = isChannel ? channeluserProfile : chatUserprofile;
  const date = formateDate(message.timestamp, "hh:mmaaa");



  let isReceived = false
  if (message.direction && message.direction === 'received') {
    isReceived = true;
  }
  const isRead = message.isSeen;
  const isSent = message.isDelivered;
  // const isForwarded = message.meta.isForwarded;
  // const channdelSenderFullname = message.meta.userData
  //   ? `${message.meta.userData.firstName} ${message.meta.userData.lastName}`
  //   : "-";
  const channdelSenderFullname = 'FULL NAME';
  const fullName = isChannel ? channdelSenderFullname : chatUserFullName;
  const onDeleteMessage = () => {
    onDelete(message.mId);
  };

  const onClickReply = () => {
    onSetReplyData(message);
  };
  const isRepliedMessage = message.replyOf;

  const onForwardMessage = () => {
    onOpenForward(message);
  };

  const onDeleteImg = (imageId: number | string) => {
    onDeleteImage(message.mId, imageId);
  };
  const Attachments = ({ attachments }: any) => {
    return (
      <>
        <div

          className={classnames("p-3", "border-primary", "border rounded-3 ")}
        >
          <div className="d-flex align-items-center attached-file ">
            <div className="flex-shrink-0 avatar-sm me-3 ms-0 attached-file-avatar">
              <div className="avatar-title bg-primary-subtle text-primary rounded-circle font-size-20">
                <i className="ri-attachment-2"></i>
              </div>
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <div className="text-start">
                <h5 className="font-size-14 mb-1">{attachments.fileName}</h5>

              </div>
            </div>
            <div className="flex-shrink-0 ms-4 ">
              <div className="d-flex gap-2 font-size-20 d-flex align-items-start">
                <div>
                  <a
                    href={`${process.env.REACT_APP_URL}/${attachments.path}`}
                    className="text-muted"
                    download
                    target="blank"
                  >
                    <i className="bx bxs-download"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <li
      className={classnames(
        "chat-list",
        { right: isFromMe },
        { reply: isRepliedMessage }
      )}
    >
      <div className="conversation-list">
        <div className="chat-avatar">
          <img src={isFromMe ? myProfile : profile} alt="" />
        </div>

        <div className="user-chat-content">
          {hasImages && (
            <div className="ctext-wrap">

              <div className="ctext-wrap-content">

                {message.messageContent !== 'undefined' &&
                  <p className="mb-0 ctext-content">{message.messageContent}</p>
                }
                <Images images={message.media[0].path} message={message} />


              </div>
            </div>
          )}
          {hasText && (
            <div className="ctext-wrap">
              <div className="ctext-wrap-content">
                <p className="mb-0 ctext-content">{message.messageContent}</p>
              </div>
            </div>
          )}
          {hasAuido && (
            <div className="ctext-wrap">
              <div className="ctext-wrap-content">
                {
                  isMp3 && (
                    <audio controls>
                      <source src={`${process.env.REACT_APP_URL}/${message.media[0].path}`} type="audio/mp3" />
                    </audio>
                  )
                }
                {
                  isOgg && (
                    <audio controls>
                      <source src={`${process.env.REACT_APP_URL}/${message.media[0].path}`} type="audio/ogg" />
                    </audio>
                  )
                }



              </div>
            </div>
          )}
          {hasPdf && (
            <div className="ctext-wrap">
              <div className="ctext-wrap-content">
                <Attachments attachments={message.media[0]} />
              </div>
            </div>
          )}
          {
            hasTemplate && (
              <div className="ctext-wrap">
                <div className="ctext-wrap-content">
                  <p className="mb-0 ctext-content">Templet sented</p>
                </div>
              </div>
            )
          }
          {/* {isForwarded && (
            <span
              className={classnames(
                "me-1",
                "text-muted",
                "font-size-13",
                "mb-1",
                "d-block"
              )}
            >
              <i
                className={classnames(
                  "ri",
                  "ri-share-forward-line",
                  "align-middle",
                  "me-1"
                )}
              ></i>
              Forwarded
            </span>
          )} */}


          <div className="conversation-name">
            {isFromMe ? (
              <>
                <span
                  className={classnames(
                    "me-1",
                    { "text-success": isRead },
                    { "text-muted": (isSent) && !isRead }
                  )}
                >
                  <i
                    className={classnames(
                      "bx",
                      { "bx-check-double": isRead },
                      { "bx-check": isSent },
                      { "bx-alarm ": !isSent }
                    )}
                  ></i>
                </span>
                <small className={classnames("text-muted", "mb-0", "me-2")}>
                  {date}
                </small>
                {message?.employeeName}
              </>
            ) : (
              <>
                {fullName}
                <small className={classnames("text-muted", "mb-0", "ms-2")}>
                  {date}
                </small>
              </>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default Message;
