import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
} from "reactstrap";

interface DataTypes {
  phone: string | null;
  name: string | null;
  businessNumber: string | null;
  contactId: string | null;
  email: string | null;
  description: string | null;
  address: string | null;
  contact_name: string | null;



}

interface InviteContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite: (data: DataTypes) => void;
}
const tamplateList = [
  { label: "Hello World", value: "hello_world" },
  { label: "Hello World", value: "hello_world1" },
  { label: "Hello World", value: "hello_world2" },

  // Add more templates as needed
];
const InviteContactModal = ({
  isOpen,
  onClose,
  onInvite,
  id
}: any) => {
  const [selectedTemplate, setSelectedTemplate] = useState(tamplateList[0].value);

  console.log(id, 'default selected template')


  const [data, setData] = useState<DataTypes>({
    phone: null,
    name: null,
    businessNumber: null,
    contactId: null,
    email: null,
    address: null,
    description: null,
    contact_name: null,
  });

  useEffect(() => {
    setData({
      phone: null,
      name: selectedTemplate ?? null,
      businessNumber: "",
      contactId: null,
      email: null,
      address: null,
      description: null,
      contact_name: null
    });
  }, []);

  const onChangeData = (field: keyof DataTypes, value: string) => {
    setData({ ...data, [field]: value });
  };

  // const onChangeData = (field: "to", value: string) => {
  //   setData({ ...data, [field]: value });
  // };
  // const onChangeDatacontactId = (field: "contactId", value: string) => {
  //   setData({ ...data, [field]: value });
  // };
  const handleTemplateChange = (e: any) => {
    setSelectedTemplate(e.target.value);
  };
  const onInviteClick = () => {
    const newData = { ...data };
    if (selectedTemplate !== '') {
      newData.name = selectedTemplate;
    }
    onInvite(newData);

    setData({
      phone: null,
      name: null,
      businessNumber: null,
      contactId: null,
      email: null,
      address: null,
      description: null,
      contact_name: null,
    })
  };
  return (
    <Modal isOpen={isOpen} toggle={onClose} tabIndex={-1} centered scrollable>
      <ModalHeader toggle={onClose} className="bg-primary">
        <div className="modal-title-custom text-white font-size-16 ">
          Create Contact
        </div>
      </ModalHeader>
      <ModalBody className="p-4">
        <Form>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Contact Name
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter Contact Name"
              value={data["contact_name"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("contact_name", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Phone Number
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter phone number"
              value={data["phone"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("phone", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Contact Id
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter contact id"
              value={data["contactId"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("contactId", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Address
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter Address"
              value={data["address"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("address", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Email
            </Label>
            <Input
              type="email"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter  Email"
              value={data["email"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("email", e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <Label htmlFor="AddContactModalname-input" className="form-label">
              Description
            </Label>
            <Input
              type="text"
              className="form-control"
              id="AddContactModalname-input"
              placeholder="Enter description"
              value={data["description"] || ""}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeData("description", e.target.value);
              }}
            />
          </div>
          {/* <div className="">
            <Label htmlFor="AddContactModal-invitemessage-input" className="form-label">
              Invitation Message
            </Label>
            <div className="input-group mb-3">
              <select
                className="form-select"
                id="inputGroupSelect02"
                onChange={handleTemplateChange}
                value={selectedTemplate}
              >
                {tamplateList.map((template) => (
                  <option key={template.value} value={template.value}>
                    {template.label}
                  </option>
                ))}
              </select>
            </div>
          </div> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button type="button" color="link" className="btn" onClick={onClose}>
          Close
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={onInviteClick}
          disabled={!data.phone}
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InviteContactModal;
