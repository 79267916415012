import React from "react";
import classnames from "classnames";

// hooks
import { useRedux } from "../../hooks/index";

// hooks
import { useConversationUserType } from "../../hooks/index";
import { createSelector } from "reselect";
// component
import Leftbar from "./Leftbar";
import ConversationUser from "./ConversationUser/index";
import UserProfileDetails from "./UserProfileDetails/index";
import Welcome from "./ConversationUser/Welcome";
import { SocketProvider } from "../../utils/SocketContext";
import { getLoggedinUser } from "../../api/apiCore";

interface IndexProps {}
const Index = (props: any) => {
  console.log(props, 'this is from dashboard index')
  // global store
  const { useAppSelector } = useRedux();

  const chatSelector = createSelector(
    (state : any) => state.Chats,
    (state: any) => state.Profile,
    (chats,profile) => ({
      selectedChat: chats.selectedChat,
      profile: profile.profileDetails
    })
  );

  // Inside your component
  const { selectedChat, profile} = useAppSelector(chatSelector);

  const {user, token} = getLoggedinUser()

  // console.log("printProfileState",profile);
  

  return (
    <SocketProvider auth={{user:user, token:token}}>
      <Leftbar />

      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": selectedChat,
        })}
        id="user-chat"
      >
        <div className="user-chat-overlay" id="user-chat-overlay"></div>
        {selectedChat !== null ? (
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <ConversationUser isChannel={false} />
            </div>
            <UserProfileDetails isChannel={false} />
          </div>
        ) : (
          <Welcome />
        )}
      </div>
    </SocketProvider>
  );
};

export default Index;
