import React from "react";

import { Button, Input, Label, UncontrolledTooltip } from "reactstrap";

// emoji picker
import Picker from "emoji-picker-react";

interface StartButtonsProps {
  onToggle: () => void;
  onEmojiClick: any;
  emojiPicker: any;
  setemojiPicker: any;
  onSelectFiles: any
}

const StartButtons = ({ onToggle, onSelectFiles, onEmojiClick, emojiPicker, setemojiPicker }: StartButtonsProps) => {
  const onSelectF = (e: any) => {
    const files = [...e.target.files];
    if (files) {
      onSelectFiles(files);

      onToggle();
    }
  };
  return (
    <div className="chat-input-links me-md-2">
      <div className="links-list-item" id="more-menu">
        <div className="text-center px-2 position-relative">
          <div>
            <Input
              id="attachedfile-input"
              type="file"
              className="d-none"
              onChange={(e: any) => onSelectF(e)}
              multiple
            />
            <Label
              htmlFor="attachedfile-input"
              className="avatar-sm mx-auto stretched-link"
            >
              <span className="avatar-title font-size-18 bg-soft-primary text-primary rounded-circle">
                <i className="bx bx-paperclip"></i>
              </span>
            </Label>
          </div>

        </div>
        {/* <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect"
          onClick={onToggle}
          color="none"
        >
          <i className="bx bx-dots-horizontal-rounded align-middle"></i>
        </Button> */}
      </div>
      {/* <UncontrolledTooltip target="more-menu" placement="top">
        More
      </UncontrolledTooltip> */}
      {emojiPicker && <Picker onEmojiClick={onEmojiClick} width={350} height={382} />}
      <div className="links-list-item" id="emoji">
        <Button
          type="button"
          className="btn btn-link text-decoration-none btn-lg waves-effect emoji-btn"
          id="emoji-btn"
          color="none"
          onClick={() => setemojiPicker(!emojiPicker)}
        >
          <i className="bx bx-smile align-middle"></i>
        </Button>
      </div>
    </div>
  );
};

export default StartButtons;
