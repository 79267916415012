import React, { useEffect, useState, ClipboardEventHandler, useRef } from "react";
import { Alert, Form } from "reactstrap";

// components
import StartButtons from "./StartButtons";
import InputSection from "./InputSection";
import EndButtons from "./EndButtons";
import MoreMenu from "./MoreMenu";
import Reply from "./Reply";

// interface
import { MessagesTypes } from "../../../../data/messages";

interface IndexProps {
  onSend: (data: any) => void;
  replyData: null | MessagesTypes | undefined;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  chatUserDetails: any;
}
const Index = ({
  onSend,
  replyData,
  onSetReplyData,
  chatUserDetails,
}: IndexProps) => {
  /*
  more menu collapse
  */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggle = () => {
    setIsOpen(!isOpen);
    setemojiArray(true);
  };

  /*
  disable send button
  */
  const [disabled, setDisabled] = useState<boolean>(true);

  /*
  input text
  */
  const [text, setText] = useState<null | string>("");
  const onChangeText = (value: string) => {
    setText(value);
  };

  /*
  images
  */
  const [images, setImages] = useState<Array<any> | null | undefined>();

  // const onSelectImages = (images: Array<any>) => {
  //   setImages(images);
  // };

  /*
  files
  */

  const inputBoxRef = useRef<HTMLInputElement>(null);
  const [autoFocus, setAutoFocus] = useState(false);

  const [files, setFiles] = useState<Array<any> | null | undefined>();
  const onSelectFiles = (files: Array<any>) => {
    setFiles(files);
  };

  useEffect(() => {
    if (text || images || files) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [text, images, files]);

  useEffect(() => {
    // inputBoxRef.current?.focus();
    setAutoFocus(prev=>!prev)
    console.log("Setting focus man," ,autoFocus);
    
  }, [chatUserDetails?._id]);


  const [recording, setRecording]: any = useState(false);
  const [mediaRecorder, setMediaRecorder]: any = useState(null);
  const [audioBlob, setAudioBlob]: any = useState('');
  const [audioURL, setAudioURL]: any = useState('');

  // emoji picker 
  const [emojiArray, setemojiArray] = useState<any>("");
  const [emojiPicker, setemojiPicker] = useState<boolean>(false);
  const onEmojiClick = (event: any) => {
    setemojiArray([...emojiArray, event.emoji]);
    setText(text + event.emoji);
  };


  // Submit Message
  const onSubmit = () => {
    let data: any = {};
    if (text) {
      data["text"] = text;
    }
    if (audioBlob) {
      data["audio"] = audioBlob;

    }
    console.log(data, 'this is data')

    if (images && images.length) {
      const imgs = (images || []).map((i: any, key: number) => {
        const src = URL.createObjectURL(i);
        return {
          id: key + 1,
          downloadLink: src,
        };
      });
      data["image"] = imgs;
    }

    if (files && files.length) {
      const fs = (files || []).map((f: any, key: number) => {
        const src = URL.createObjectURL(f);
        return {
          id: key + 1,
          name: f.name,
          downloadLink: src,
          desc: f.size,
        };
      });
      data["attachments"] = files;
    }

    setText("");
    setImages(null);
    setFiles(null);
    setemojiPicker(false);
    onSend(data);
    setAudioBlob("");
    setMediaRecorder(null);
    setRecording(false);
    setAudioURL("");
  };

  const onClearMedia = () => {
    setImages(null);
    setFiles(null);
    setAudioBlob("");
    setMediaRecorder(null);
    setRecording(false);
    setAudioURL("");
  };




  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const chunks: any = [];

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
        setAudioBlob(blob);
        const url = URL.createObjectURL(blob);
        setAudioURL(url);
      };

      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const items = event.clipboardData?.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const blob = items[i].getAsFile();
          console.log(blob);
          
          if (blob) {
            setFiles(prevImages => [...prevImages??[], blob]);
            const reader = new FileReader();
            reader.onload = (event) => {
              if (event.target?.result) {
                console.log('images from paste', images);
                
                
              }
            };
            reader.readAsDataURL(blob);
          }
        }
      }
    }
  };

  console.log(audioBlob, 'this is audio blob')

  return (
    <div className="chat-input-section p-3 p-lg-4">
      <Form
        id="chatinput-form"
        onSubmit={(e: any) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <div className="row g-0 align-items-center">
          <div className="col-auto">
            <StartButtons
              onToggle={onToggle}
              onEmojiClick={onEmojiClick}
              setemojiPicker={setemojiPicker}
              emojiPicker={emojiPicker}
              onSelectFiles={onSelectFiles}
            />
          </div>
          <div className="col">
            <InputSection  value={text} onChange={onChangeText} onPaste={handlePaste} autoFocus={autoFocus}  />
          </div>
          <div className="col-auto">
            <EndButtons
              recording={recording}
              startRecording={startRecording}
              stopRecording={stopRecording}
              onSubmit={onSubmit}
              disabled={disabled} />
          </div>
        </div>
      </Form>

      {audioURL ? (<Alert
        isOpen={true}
        toggle={onClearMedia}
        color="secondary"
        className="alert-dismiss-custom 
        rounded-pill font-size-12 mb-1 selected-media"
        closeClassName="selected-media-close"
      ><audio controls src={audioURL} /></Alert>) : null}

      {(images && images.length) || (files && files.length) ? (
        <Alert
          isOpen={true}
          toggle={onClearMedia}
          color="secondary"
          className="alert-dismiss-custom 
        rounded-pill font-size-12 mb-1 selected-media"
          closeClassName="selected-media-close"
          style={{position:'absolute',top:'-55px',width:'70%', alignContent:'center'}} 
        >
          <p className="me-2 mb-0">
            {images && !files && ` You have selected ${images.length} images`}
            {files && !images && ` You have selected ${files.length} files`}
            {files &&
              images &&
              ` You have selected ${files.length} files & ${images.length} images.`}
          </p>
        </Alert>
      ) : null}

      {/* <MoreMenu
        isOpen={isOpen}
        onSelectImages={onSelectImages}
        onSelectFiles={onSelectFiles}
        onToggle={onToggle}
      /> */}

      <Reply
        reply={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </div>
  );
};

export default Index;
