import React, { useRef } from "react";

import { Input } from "reactstrap";


interface InputSectionProps {
  value: null | string;
  autoFocus:any;
  onChange: (value: string) => void;
  onPaste: (event?: any) => void;
}

const InputSection = ({ value, onChange, onPaste, autoFocus }: InputSectionProps) => {
  // console.log("value",value)
  
  return (
    <div className="position-relative">
      <Input
        type="text"
        className="form-control form-control-lg chat-input"
        id="chat-input"
        placeholder="Type your message..."
        value={value || ""}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        onPaste={onPaste}
        autoComplete="off"
        // ref={inputBoxRef}
        autoFocus={autoFocus}
      />
      {/* <VoiceRecorder /> */}
    </div>
  );
};
export default InputSection;
