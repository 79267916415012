import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//  Redux States
import { ContactsActionTypes } from "./types";
import {
  contactsApiResponseSuccess,
  contactsApiResponseError,
  singleContact,
} from "./actions";

// api
import {
  getContacts as getContactsApi,
  inviteContact as inviteContactApi,
  updateContact as updateContactApi,
  getSingleContact as getSingleContactApi

} from "../../api/index";

// helpers
import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";


function* updateContact({ payload: { id, data } }: any) {
  try {
    console.log(id,'this is sage id')
    const response: Promise<any> = yield call(updateContactApi, id, data);
    yield put(
      contactsApiResponseSuccess(
        ContactsActionTypes.UPDATE_CONTACT,
        response
      )
    );
    console.log(response,'this update msg')
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      contactsApiResponseError(ContactsActionTypes.UPDATE_CONTACT, error)
    );
  }
}
function* getSingleContact({ payload: filters }: any) {
  try {
    const response: Promise<any> = yield call(getSingleContactApi, filters);
    console.log(response, 'this is from saga');
    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.GET_SINGLE_CONTACT, response)
    );
  } catch (error: any) {
    yield put(
      contactsApiResponseError(ContactsActionTypes.GET_SINGLE_CONTACT, error)
    );
  }
}

function* getContacts({ payload: filters }: any) {
  try {
    const response: Promise<any> = yield call(getContactsApi, filters);
    console.log(response,'this is from saga');
    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.GET_CONTACTS, response)
    );
  } catch (error: any) {
    yield put(
      contactsApiResponseError(ContactsActionTypes.GET_CONTACTS, error)
    );
  }
}


function* inviteContact({ payload: newPassword }: any) {
  try {
    const response: Promise<any> = yield call(inviteContactApi, newPassword);
    yield put(
      contactsApiResponseSuccess(ContactsActionTypes.INVITE_CONTACT, response)
    );
    yield call(showSuccessNotification, response);
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(
      contactsApiResponseError(ContactsActionTypes.INVITE_CONTACT, error)
    );
  }
}

export function* watchGetContacts() {
  yield takeEvery(ContactsActionTypes.GET_CONTACTS, getContacts);
}

export function* watchInviteContact() {
  yield takeEvery(ContactsActionTypes.INVITE_CONTACT, inviteContact);
}
export function* watchUpdateContact() {
  yield takeEvery(ContactsActionTypes.UPDATE_CONTACT, updateContact);
}
export function* watchSingleContact() {
  yield takeEvery(ContactsActionTypes.GET_SINGLE_CONTACT, getSingleContact)
}

function* contactsSaga() {
  yield all([fork(watchGetContacts),
  fork(watchInviteContact),
  fork(watchUpdateContact),
  fork(watchSingleContact)

  ]);
}

export default contactsSaga;
