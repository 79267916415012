import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import { useRedux } from "../../../hooks/useRedux";
import { createSelector } from "reselect";
import { onSendMessage } from "../../../redux/chats/actions";
import { useProfile } from "../../../hooks/UserHooks";
interface AttachedFilesProps {
  chatUserDetails: any;
  onOpenVideo: () => void;
  onOpenAudio: () => void;
  onToggleFavourite: () => void;
  onToggleArchive: () => void;
}
const AttachedFiles = ({
  chatUserDetails,
  onOpenVideo,
  onOpenAudio,
  onToggleFavourite,
  onToggleArchive,
}: AttachedFilesProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const { dispatch, useAppSelector } = useRedux();
  const { userProfile } = useProfile();

  const [askPrice, setAskPrice] = useState(0)
  const [bidPrice, setBidPrice] = useState(0)

  const [isDisabled, setIsDisabled] = useState(false);
  // const ConversationSelector = createSelector(
  //   (state: any) => state.Chats,
  //   (state) => ({
  //     chatUserDetails: state.chatUserDetails,
  //     // chatUserConversations: state.chatUserConversations,
  //     // isUserMessageSent: state.isUserMessageSent,
  //     // isMessageDeleted: state.isMessageDeleted,
  //     // isMessageForwarded: state.isMessageForwarded,
  //     // isUserMessagesDeleted: state.isUserMessagesDeleted,
  //     // isImageDeleted: state.isImageDeleted,
  //   })
  // );

  // const { chatUserDetails, chatUserConversations, isUserMessageSent, isMessageDeleted, isMessageForwarded, isUserMessagesDeleted,
  //   isImageDeleted } = useAppSelector(ConversationSelector);

  useEffect(() => {

    const ws = new WebSocket(process.env.REACT_APP_GOLD_PRICE_WS || '');

    ws.onopen = () => {
      console.log('Connected to the server');
      ws.send('Hello from client');
    };

    ws.onmessage = (event) => {
      console.log(`Message from server: ${event.data}`);
      const data = JSON.parse(event.data);
      setAskPrice(data.askPrice)
      setBidPrice(data.bidPrice)
    };

    ws.onclose = () => {
      console.log('Disconnected from the server');
    };

  }, [

  ]);


  const onSend = (msg: any) => {

    let params: any = {
      text: msg && msg,
      time: new Date().toISOString(),
      to: chatUserDetails.whatsAppContact,
      type: 'text',
      meta: {
        receiver: chatUserDetails.whatsAppContact,
        sender: userProfile.user.id,
      },
    };

    dispatch(onSendMessage(params));

    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 5000);
  }

  return (
    <div className="text-center border-bottom">
      <div className="row">
        <div className="col-sm-6 col-12">
          <div className="mb-4">
            <Button color="none" disabled={isDisabled} type="button" className="btn primary avatar-sm p-0 w-100" onClick={() => onSend('Buy Order Confirmed....\n\nRate: #br')}>
              <span className="avatar-title rounded bg-info text-white">
                {/* <i className="bx bxs-message-alt-detail"></i> */}{askPrice}
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Buy Order (ASK)
            </h5>
          </div>
        </div>
        <div className="col-sm-6 col-12">
          <div className="mb-4">
            <Button color="none" disabled={isDisabled} type="button" className="btn avatar-sm p-0 w-100" onClick={() => onSend('Sell Order Confirmed....\n\nRate: #sr')}>
              <span className="avatar-title rounded bg-danger text-white">
                {/* <i className="bx bxs-message-alt-detail"></i> */}{bidPrice}
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Sell Order (BID)
            </h5>
          </div>
        </div>
        <div className="col-sm-6 col-12">
          <div className="mb-4">
            <Button color="none" disabled={isDisabled} type="button" className="btn avatar-sm p-0 w-100" onClick={() => onSend('Buy Close Confirmed....\n\nRate: #sr')}>
              <span className="avatar-title rounded bg-info text-white">
                {/* <i className="bx bxs-message-alt-detail"></i> */}{bidPrice}
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Buy Close
            </h5>
          </div>
        </div>
        <div className="col-sm-6 col-12">
          <div className="mb-4">
            <Button color="none" disabled={isDisabled} type="button" className="btn avatar-sm p-0 w-100" onClick={() => onSend('Sell Close Confirmed....\n\nRate: #br')}>
              <span className="avatar-title rounded bg-danger text-white">
                {/* <i className="bx bxs-message-alt-detail"></i> */}{askPrice}
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Sell Close
            </h5>
          </div>
        </div>
        {/* <div className="col-sm-6 col-6">
          <div className="mb-4">
            <Button
              color="none"
              className={classnames(
                "btn",
                "avatar-sm",
                "p-0",
                "favourite-btn",
                { active: chatUserDetails.isFavourite }
              )}
              onClick={onToggleFavourite}
            >
              <span className="avatar-title rounded bg-danger text-body">
                <i className="bx bx-heart"></i>wwwwwww
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Favourite
            </h5>
          </div>
        </div>
        <div className="col-sm col-6">
          <div className="mb-4">
            <Button
              color="none"
              className="btn avatar-sm p-0"
              onClick={onOpenAudio}
            >
              <span className="avatar-title rounded bg-light text-body">
                <i className="bx bxs-phone-call"></i>
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Audio
            </h5>
          </div>
        </div>
        <div className="col-sm col-6">
          <div className="mb-4">
            <Button
              color="none"
              type="button"
              className="btn avatar-sm p-0"
              onClick={onOpenVideo}
            >
              <span className="avatar-title rounded bg-light text-body">
                <i className="bx bx-video"></i>
              </span>
            </Button>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              Video
            </h5>
          </div>
        </div>
        <div className="col-sm col-6">
          <div className="mb-4">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                color="none"
                className="btn avatar-sm p-0 dropdown-toggle"
                type="button"
              >
                <span className="avatar-title bg-light text-body rounded">
                  <i className="bx bx-dots-horizontal-rounded"></i>
                </span>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  className=" d-flex justify-content-between align-items-center"
                  to="#"
                  onClick={onToggleArchive}
                >
                  {chatUserDetails.isArchived ? (
                    <>
                      Un-Archive{" "}
                      <i className="bx bx-archive-out text-muted"></i>
                    </>
                  ) : (
                    <>
                      Archive <i className="bx bx-archive text-muted"></i>
                    </>
                  )}
                </DropdownItem>
                <DropdownItem
                  className=" d-flex justify-content-between align-items-center"
                  to="#"
                >
                  Muted <i className="bx bx-microphone-off text-muted"></i>
                </DropdownItem>
                <DropdownItem
                  className=" d-flex justify-content-between align-items-center"
                  to="#"
                >
                  Delete <i className="bx bx-trash text-muted"></i>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <h5 className="font-size-11 text-uppercase text-muted mt-2">
              More
            </h5>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AttachedFiles;
