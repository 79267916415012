import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// interface
import { UserTypes } from "../../../data/chat";
import { STATUS_TYPES } from "../../../constants";

interface ChatUserProps {
  user: any;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}
const ChatUser = ({ user, selectedChat, onSelectChat }: ChatUserProps) => {

  console.log(user, 'this is user')
  const fullName = `${user.contactDetails.name ? user.contactDetails.name : user.contactDetails.whatsAppContact}`;
  const shortName = `${user?.contactDetails.name.substr(0, 2)}`;
  // console.log(user, 'this is user from chat user');
  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));
  const isOnline = user.status && user.status === STATUS_TYPES.ACTIVE;

  const [receiveCount, setReceiveCount] = useState(user && user.userVSinbox[0] && (user.receiveCount - user.userVSinbox[0].receiveCount))
  const [sendCount, setSendCount] = useState(user && user.userVSinbox[0] && (user.sendCount - user.userVSinbox[0].sendCount))

  useEffect(() => {
    console.log("Chat user rendered: 1) ", user);

    setReceiveCount(user && user.userVSinbox[0] && (user.receiveCount - user.userVSinbox[0].receiveCount))
    setSendCount(user && user.userVSinbox[0] && (user.sendCount - user.userVSinbox[0].sendCount))
    return () => {
    };
  }, [user?._id, (user.overallCount - user?.userVSinbox[0]?.overallCount)]);

  useEffect(() => {
    console.log("Chat user rendered: ", user, user?.contactDetails?.name, user._id, user?.userVSinbox[0]?.overallCount);
    return () => {
    };
  });

  const isSelectedChat: boolean =
    selectedChat && selectedChat === user.id ? true : false;
  const onClick = () => {
    onSelectChat(user.contactDetails._id);
  };

  return (
    <li className={classnames({ active: isSelectedChat })} onClick={onClick}>
      <Link to="#" className={classnames({ "unread-msg-user": receiveCount })}>
        <div className="d-flex align-items-center">
          <div
            className={classnames(
              "chat-user-img",
              "align-self-center",
              "me-2",
              "ms-0",
              { online: isOnline }
            )}
          >
            {user.profileImage ? (
              <>
                <img
                  src={user.profileImage}
                  className="rounded-circle avatar-xs"
                  alt=""
                />
                {isOnline && <span className="user-status"></span>}
              </>
            ) : (
              <div className="avatar-xs">
                <span
                  className={classnames(
                    "avatar-title",
                    "rounded-circle",
                    "text-uppercase",
                    "text-white",
                    colors[color]
                  )}
                >
                  <span className="username">{shortName}</span>

                  {/* <span className="user-status"></span> */}
                </span>
              </div>
            )}
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate mb-0">{fullName}</p>
            {(user?.employeeDetails ? (<p className="text-truncate mb-0 text-success"> <i className="bx bxs-message-square-check mb-1 align-middle  me-1"></i>{user?.employeeDetails}</p>) : (<p className="text-truncate mb-0 text-danger"> <i className="bx bxs-message-square-error mb-1 align-middle  me-1"></i>{'Not Assigned'}</p>))}
            {/* <p className="text-truncate mb-0">In:25</p>
            <p className="text-truncate mb-0">Out:21</p> */}



          </div>
          <div className="ms-auto text-sm ">
            {receiveCount && receiveCount > 0 ? (
              <div className="text-danger">
                <i className="bx bxs-chevron-down mb-1 align-middle size-xs "></i>
                <span className="align-right"> {String(receiveCount).padStart(2, ` `)}</span>
              </div>
            ) : null}
            {sendCount && sendCount > 0 ? (
              <div className="text-info">
                <i className="bx bxs-chevron-up align-middle"></i>
                <span className="align-right"> {String(sendCount).padStart(2, ` `)}</span>
              </div>
            ) : null}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default ChatUser;
