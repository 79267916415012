import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

// interface
import { UserTypes } from "../../../data/chat";
import { STATUS_TYPES } from "../../../constants";
import { useRedux } from "../../../hooks/useRedux";
import { getDirectMessages } from "../../../redux/actions"

interface ChatUserProps {
  chat: any;
  selectedChat: string | number;
  onSelectChat: (id: number | string) => void;
}
const ChatUser = ({ chat, selectedChat, onSelectChat }: ChatUserProps) => {

  console.log(chat, 'this is chat')
  const fullName = `${chat.contactDetails.name ? chat.contactDetails.name : chat.contactDetails.whatsAppContact}`;
  const contactID = `${chat.contactDetails.contactId ? chat.contactDetails.contactId : chat.contactDetails.whatsAppContact}`;
  const shortName =  `${chat.contactDetails ? chat.contactDetails.name.split(' ').map((word:any) => word.charAt(0)).join('').substring(0, 2) : 'N/A'}`
  // console.log(chat, 'this is chat from chat chat');
  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));
  const isOnline = chat.status && chat.status === STATUS_TYPES.ACTIVE;

  const { dispatch, useAppSelector } = useRedux();

  const [receiveCount, setReceiveCount] = useState(chat && chat.userVSinbox[0] && (chat.receiveCount - chat.userVSinbox[0].receiveCount))
  const [sendCount, setSendCount] = useState(chat && chat.userVSinbox[0] && (chat.sendCount - chat.userVSinbox[0].sendCount))
  const [convStatus, setConvStatus] = useState(chat && chat?.conversationStatus)

  useEffect(() => {
    console.log("Chat chat rendered: 1) ", chat);

    if (selectedChat && selectedChat === chat.contactDetails._id){
      console.log("Chat chat rendered: 1o) ",selectedChat);
      setReceiveCount(0)
      setSendCount(0)
      if (chat && chat.userVSinbox[0]){
        chat.userVSinbox[0].receiveCount = chat.receiveCount
        chat.userVSinbox[0].sendCount = chat.sendCount
      }
    }else{
      setReceiveCount(chat && chat.userVSinbox[0] && (chat.receiveCount - chat.userVSinbox[0].receiveCount))
      setSendCount(chat && chat.userVSinbox[0] && (chat.sendCount - chat.userVSinbox[0].sendCount))
    }

    // if(chat?.conversationStatus === 'closed'){
    //   dispatch(getDirectMessages());
    // }

    return () => {
    };
  }, [chat?._id, chat.overallCount , selectedChat, chat?.conversationStatus??'']);

  // useEffect(() => {
  //   console.log("Chat chat rendered: ", chat, chat?.contactDetails?.name, chat._id, chat?.userVSinbox[0]?.overallCount);
  //   return () => {
  //   };
  // });

  const isSelectedChat: boolean =
    selectedChat && selectedChat === chat.contactDetails._id ? true : false;
  const onClick = () => {
    onSelectChat(chat.contactDetails._id);
  };

  const chatStatusRender = (status:string) => {
    switch (status) {
      case 'not_assigned':
        return (<p className="text-truncate mb-0 text-danger" style={{fontSize:'11px'}}> <i className="bx bxs-message-square-error mb-1 align-middle  me-1"></i>{'Not Assigned'}</p>);
      case 'assigned':
        return (<p className="text-truncate mb-0 text-success" style={{fontSize:'11px'}}> <i className="bx bxs-message-square-check mb-0 align-middle  me-1"></i>{chat?.employeeDetails??''}</p>);
      case 'open':
        return (<p className="text-truncate mb-0 text-success" style={{fontSize:'11px'}}> <i className="bx bxs-message-square-check mb-0 align-middle  me-1"></i>{chat?.employeeDetails??''}</p>);
      case 'closed':
        return (<p className="text-truncate mb-0 text-black" style={{fontSize:'11px'}}> <i className="bx bxs-message-square-error mb-1 align-middle  me-1"></i>{'Closed'}</p>);
      default:
        return <div></div>;
    }
  }

  return (
    <li className={classnames({ active: isSelectedChat })+' mb-1'} onClick={onClick}>
      <Link to="#" className={classnames({ "unread-msg-chat": receiveCount })}>
        <div className="d-flex align-items-center">
          <div
            className={classnames(
              "chat-chat-img",
              "align-self-center",
              "me-2",
              "ms-0",
              { online: isOnline }
            )}
          >
            {chat.profileImage ? (
              <>
                <img
                  src={chat.profileImage}
                  className="rounded-circle avatar-xs"
                  alt=""
                />
                {isOnline && <span className="chat-status"></span>}
              </>
            ) : (
              <div className="avatar-xs">
                <span
                  className={classnames(
                    "avatar-title",
                    "rounded-circle",
                    "text-uppercase",
                    "text-white",
                    colors[`${shortName.charCodeAt(0)%colors.length}`]
                  )}
                >
                  <span className="username text-sm">{shortName}</span>

                  {/* <span className="chat-status"></span> */}
                </span>
              </div>
            )}
          </div>
          <div className="overflow-hidden">
            <p className="text-truncate mb-0">{fullName}</p>
            <p className="text-truncate mb-0 mt-0 d-none" style={{fontSize:'10px'}} ><span className="text-danger">@</span>{contactID}</p>
            {(chatStatusRender(chat?.conversationStatus))}
            {/* <p className="text-truncate mb-0">In:25</p>
            <p className="text-truncate mb-0">Out:21</p> */}



          </div>
          <div className="ms-auto text-sm ">
            {receiveCount && receiveCount > 0 ? (
              <div className="text-danger">
                <i className="bx bxs-chevron-down mb-1 align-middle size-xs "></i>
                <span className="align-right"> {String(receiveCount).padStart(2, ` `)}</span>
              </div>
            ) : null}
            {sendCount && sendCount > 0 ? (
              <div className="text-info">
                <i className="bx bxs-chevron-up align-middle"></i>
                <span className="align-right"> {String(sendCount).padStart(2, ` `)}</span>
              </div>
            ) : null}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default ChatUser;
