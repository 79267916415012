// SocketContext.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { getLoggedinUser } from '../api/apiCore';

interface InboxData {
    chatId: string;
    type: string;
    sendCount: number;
    receiveCount: number;
    overallCount: number;
}

interface SocketContextProps {
    socket: Socket;
    inboxData: InboxData | null;
}

const SocketContext = createContext<SocketContextProps | undefined>(undefined);

export const useSocketContext = (): SocketContextProps => {
    const context = useContext(SocketContext);
    if (!context) {
        throw new Error('useSocketContext must be used within a SocketProvider');
    }
    return context;
};

interface SocketProviderProps {
    auth:any;
    children: React.ReactNode;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ auth, children }) => {
    
    const [socket, setSocket]: any = useState<Socket | null>(null);
    const [inboxData, setInboxData] = useState<InboxData | null>(null);
    const [isConnected, setIsConnected] = useState<boolean>(false);

    useEffect(() => {
        if(!auth?.user?.id) return;

        const newSocket = io(`${process.env.REACT_APP_SOKET_IO_URL}?id=${auth?.user?.id}`);

        newSocket.on('connect', () => {
            console.log('printProfileState1',auth);
            
            setIsConnected(true);
            console.log('Socket is connected');
        });

        newSocket.on('disconnect', () => {
            setIsConnected(false);
            console.log('Socket is disconnected');
            console.log('printProfileState1',auth);
        });

        newSocket.on('inbox.update', (data: InboxData) => {
            setInboxData(data);
            console.log('Inbox update received:', data);
        });

        setSocket(newSocket);

        return () => {
            newSocket.disconnect();
        };
    }, [auth?.user?.id]);

    return (
        <SocketContext.Provider value={{ socket, inboxData, }}>
            {children}
        </SocketContext.Provider>
    );
};
