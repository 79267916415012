import React, { useEffect, useState } from "react";

// hooks
import { useRedux } from "../../../hooks/index";
import { createSelector } from "reselect";
// actions
import {
  toggleUserDetailsTab,
  getChatUserConversations,
  onSendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  deleteUserMessages,
  toggleArchiveContact,
  closeChat,
  getDirectMessages,
  changeSelectedChat,
} from "../../../redux/actions";

// hooks
import { useProfile } from "../../../hooks";

// components
import UserHead from "./UserHead";
import Conversation from "./Conversation";
import ChatInputSection from "./ChatInputSection/index";

// interface
import { MessagesTypes } from "../../../data/messages";

// dummy data
import { pinnedTabs } from "../../../data/index";
import { useSocketContext } from "../../../utils/SocketContext";

interface IndexProps {
  isChannel: boolean;
}
const Index = ({ isChannel }: IndexProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const ConversationSelector = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      chatUserDetails: state.chatUserDetails,
      chatUserConversations: state.chatUserConversations,
      isUserMessageSent: state.isUserMessageSent,
      isMessageDeleted: state.isMessageDeleted,
      isMessageForwarded: state.isMessageForwarded,
      isUserMessagesDeleted: state.isUserMessagesDeleted,
      isImageDeleted: state.isImageDeleted,
    })
  );
  // Inside your component
  const { chatUserDetails, chatUserConversations, isUserMessageSent, isMessageDeleted, isMessageForwarded, isUserMessagesDeleted,
    isImageDeleted } = useAppSelector(ConversationSelector);

  const onOpenUserDetails = () => {
    dispatch(toggleUserDetailsTab(true));
  };
  const { inboxData } = useSocketContext();
  console.log(inboxData, 'its come from via converstion user---------------')
  const responseInbox: any = inboxData;
  /*
  hooks
  */
  const { userProfile } = useProfile();
  console.log(userProfile, 'userProfile');

  /*
  reply handeling
  */
  const [replyData, setReplyData] = useState<
    null | MessagesTypes | undefined
  >();
  const onSetReplyData = (reply: null | MessagesTypes | undefined) => {
    setReplyData(reply);
  };
  console.log(chatUserDetails, 'chatUserDetails')
  /*
  send message
  */

  const onCloseChat = () => {
    console.log("press close chat ", chatUserConversations._id);

    dispatch(closeChat(chatUserConversations._id));

    setTimeout(() => {
      dispatch(getDirectMessages());
      dispatch(changeSelectedChat(null));
    }, 1000);
  }

  const keyWordConverter = (str: any) => {
    let msgToSend = ''
    switch (str) {
      case '#bo':
        msgToSend = 'Buy Order Confirmed....\n\nRate: #br'
        break;
      case '#so':
        msgToSend = 'Sell Order Confirmed....\n\nRate: #sr'
        break;
      case '#bc':
        msgToSend = 'Buy Close Confirmed....\n\nRate: #sr'
        break;
      case '#sc':
        msgToSend = 'Sell Close Confirmed....\n\nRate: #br'
        break;
      default:
        msgToSend = str
    }
    return msgToSend;
  }

  const onSend = (data: any) => {
    console.log(data, 'this is inside onsubmit og message')

    if (!(data.attachments && data.attachments[0] || data.audio)) {
      if (data == undefined || data == null || !('text' in data))
        return;
    }

    data.text = keyWordConverter(data.text)

    let params: any = {
      text: data.text && data.text,
      time: new Date().toISOString(),
      image: data.image && data.image,
      newimage: data.newimage && data.newimage,
      file: data.attachments ? data.attachments[0] : data.audio ?? "",

      to: chatUserDetails.whatsAppContact,
      // type: 'text',

      meta: {
        receiver: chatUserDetails.whatsAppContact,
        sender: userProfile.user.id,
      },
    };
    console.log(params, 'jello')

    if (replyData && replyData !== null) {
      params["replyOf"] = replyData;
    }

    dispatch(onSendMessage(params));
    // if (!isChannel) {
    //   setTimeout(() => {
    //     dispatch(receiveMessage(chatUserDetails._id));
    //   }, 1000);
    //   setTimeout(() => {
    //     dispatch(readMessage(chatUserDetails._id));
    //   }, 1500);
    //   setTimeout(() => {
    //     dispatch(receiveMessageFromUser(chatUserDetails._id));
    //   }, 2000);
    // }
    setReplyData(null);
  };

  console.log(chatUserConversations, 'this is from index')

  useEffect(() => {
    if (chatUserConversations.length !== 0 && chatUserDetails._id !== undefined && chatUserDetails._id !== null) {
      dispatch(getChatUserConversations(chatUserDetails._id));
    }

  }, [
    dispatch,
    // isUserMessageSent,
    // isMessageDeleted,
    // isMessageForwarded,
    // isUserMessagesDeleted,
    // isImageDeleted,
    chatUserConversations?.length
  ]);

  useEffect(() => {

    if (responseInbox?.chatId == chatUserConversations?._id && chatUserDetails._id !== undefined && chatUserDetails._id !== null)
      dispatch(getChatUserConversations(chatUserDetails._id));

  }, [
    responseInbox?.chatId, responseInbox?.overallCount
  ]);

  const onDeleteMessage = (messageId: string | number) => {
    dispatch(deleteMessage(chatUserDetails.id, messageId));
  };

  const onDeleteUserMessages = () => {
    dispatch(deleteUserMessages(chatUserDetails.id));

  };

  const onToggleArchive = () => {
    dispatch(toggleArchiveContact(chatUserDetails.id));
  };
  return (
    <>
      <UserHead
        chatUserDetails={chatUserDetails}
        pinnedTabs={pinnedTabs}
        onOpenUserDetails={onOpenUserDetails}
        onDelete={onCloseChat}
        isChannel={isChannel}
        onToggleArchive={onToggleArchive}
      />
      <Conversation
        chatUserConversations={chatUserConversations}
        chatUserDetails={chatUserDetails}
        onDelete={onDeleteMessage}
        onSetReplyData={onSetReplyData}
        isChannel={isChannel}
      />
      <ChatInputSection
        onSend={onSend}
        replyData={replyData}
        onSetReplyData={onSetReplyData}
        chatUserDetails={chatUserDetails}
      />
    </>
  );
};

export default Index;
